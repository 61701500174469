import React,{useEffect} from "react";
import style from './index.module.less'
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import useBigScreen from "@/hooks/bigScreen"
import getCountry from '@/utils/getCountry.js'
import { buy_link } from '@/scripts/constant.js'

const ipd_1 =  `${process.env.resourceUrl}images/ipd/ipd_1.webp`
const ipd_2 =  `${process.env.resourceUrl}images/ipd/ipd_2.webp`
const ipd_3 =  `${process.env.resourceUrl}images/ipd/ipd_3.webp`
const ipd_googleplay =  `${process.env.resourceUrl}images/ipd/ipd_googleplay.svg`
const ipd_appstore =  `${process.env.resourceUrl}images/ipd/ipd_appstore.svg`

const Index = () => {
    const ifPc = useBigScreen(768)
    const way_arr = [
        {
            img: ipd_1,
            type: "Most Recommended",
            title: "Get checked by an Optometrist ",
            desc: "Visit an optometrist in a physical store to have your IPD measured professionally. This method will provide the most accurate result."
        }, {
            img: ipd_2,
            type: "Ruler Required, may be errors",
            title: "Measure It Yourself",
            desc: "You can measure the distance between your pupils on your own using a physical straight ruler. Follow the diagram below for an example of how to measure. "
        },
        {
            img: ipd_3,
            type: "May be errors, for reference only",
            title: "Use a Mobile App",
            desc: "Using a mobile app to measure your IPD can be convenient, but there is a margin for error to account for (about 0.08\"/2mm). <br/>If you choose to use an app, it's recommended to measure several times and calculate the average for a more accurate result."
        }
    ]
  const countryCode = getCountry()

   
    return (
        <Layout menuBlack={true}>
            <div className={style.box}>
                {ifPc ? <>
                    <div className={style.title}>
                        <div className={style.titleHead}>
                            How to Measure Your IPD?
                        </div>
                        {countryCode!=='jp'&&countryCode!=='kr'&& <div className={style.titleShop} onClick={()=>window.open(buy_link[countryCode]['onepro'].shop)}>
                            SHOP XREAL ONE PRO
                        </div>}
                       
                    </div>
                    <div className={style.desc}>
                        IPD: Inter-Pupillary Distance
                    </div>
                </> : <>
                    <div className={style.title}>
                        <div className={style.titleHead}>
                            How to Measure Your IPD?
                        </div>

                    </div>
                    <div className={style.desc}>
                        IPD: Inter-Pupillary Distance
                    </div>
                    {countryCode!=='jp'&&countryCode!=='kr'&&<div className={style.titleShop} onClick={()=>window.open(buy_link[countryCode]['onepro'].shop)}>
                        SHOP XREAL ONE PRO
                    </div>}
                    
                </>}


                <div className={style.way}>
                    {
                        way_arr.map((item, index) => (
                            <div className={style.wayBox} key={`ipd_way_${index}`}>
                                <div className={style.wayBoxImg} style={{ backgroundImage: `url(${item.img})` }}>

                                </div>
                                <div className={style.wayBoxType}>
                                    {item.type}
                                </div>
                                <div className={style.wayBoxTitle}>
                                    {item.title}
                                </div>
                                <div className={style.wayBoxDesc} dangerouslySetInnerHTML={{ __html: `${item.desc}` }}>
                                </div>
                                {index==2&&<div className={style.wayBoxBtns}>
                                    <img src={ipd_googleplay} onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.sixoversix.copyglass')} />
                                    <img src={ipd_appstore} onClick={()=>window.open('https://apps.apple.com/us/app/glasseson/id1153638659')}/>    
                                </div>}
                            </div>
                        ))
                    }
                </div>
            </div>

        </Layout>

    )
}

export default Index